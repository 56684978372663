<template>
  <div class="corners-goals">
    <div class="corners-goals__title">{{ typeTitle }} por minutos:</div>
    <div class="corners-goals__playing-as">
      <RadioPlayingAs :is-mobile="isRadioMobile" :section="typeTitle + ' por minutos'" />
    </div>
    <div class="corners-goals__teams">
      <div class="corners-goals__teams__team">
        <div class="corners-goals__teams__team__logo">
          <img :src="homeTeamLogo" alt="" />
        </div>
        <div class="corners-goals__teams__team__charts">
          <ChartsDonutAndColumns :type="type" />
        </div>
      </div>
      <div class="corners-goals__teams__team is-away-team">
        <div class="corners-goals__teams__team__logo">
          <img :src="awayTeamLogo" alt="" />
        </div>
        <div class="corners-goals__teams__team__charts">
          <ChartsDonutAndColumns is-away-team :type="type" />
        </div>
      </div>
    </div>
    <LegendLastSeason :mobile="isRadioMobile" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CornersAndGoalsPerMinute',
  components: {
    LegendLastSeason: () => import('@/components/Sections/GolTipster-v2/General/LegendLastSeason'),
    RadioPlayingAs: () => import('@/components/Sections/GolTipster-v2/General/RadioPlayingAs'),
    ChartsDonutAndColumns: () => import('@/components/Sections/GolTipster-v2/General/ChartsDonutAndColumns'),
  },
  props: {
    type: {
      type: String,
      default: 'corners', // 'corners' or 'goals'
    },
  },
  data() {
    return {
      teamsLogosUrl: 'https://az755631.vo.msecnd.net/teams-150/',
    };
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapState('betsGeneral', ['selectedGame']),

    homeTeamLogo() {
      return `${this.teamsLogosUrl}${this.selectedGame.homeTeam.team_id}.png`;
    },

    awayTeamLogo() {
      return `${this.teamsLogosUrl}${this.selectedGame.awayTeam.team_id}.png`;
    },

    isRadioMobile() {
      return this.displayWidth < 464;
    },

    typeTitle() {
      return this.type === 'corners' ? 'Tiros de esquina' : 'Goles';
    },
  },
};
</script>

<style scoped lang="scss">
.leyend {
  width: 100%;
  background: red;
}
.corners-goals {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 1.0625rem;
    font-family: Roboto-Regular, sans-serif;
    font-weight: 600;
    color: #4d4f52;
    line-height: 1.06;

    @media screen and (max-width: 912px) {
      font-size: 1.375rem;
    }
  }

  &__playing-as {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  &__teams {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    &__team {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &__logo {
        height: 7.75rem;
        width: 7.75rem;
      }

      &__charts {
        width: 100%;
        max-width: 18.75rem;
      }

      &:first-child {
        margin-right: 1.5%;
      }

      &:last-child {
        margin-left: 1.5%;
      }
    }

    @media screen and (max-width: 912px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &__team {
        width: fit-content;
        flex-direction: column !important;
        align-items: center !important;
        border-radius: 24px;
        border: solid 1px #cbee6b;
        padding: 1rem;

        &__charts {
          margin-top: 1rem;
        }

        &:first-child {
          margin-right: 0;
          margin-bottom: 0.625rem;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}
.is-away-team {
  flex-direction: row-reverse;
}
</style>
